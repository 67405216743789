import classNames from 'classnames';
import { throttle } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import CarouselItem from '@components/utilities/CarouselItem/CarouselItem';
import CarouselPagination from '@components/utilities/CarouselPagination/CarouselPagination';
import { useActiveBreakpoint } from '@hooks/useActiveBreakpoint';
import ArrowSvg from '@icons/arrow-sharp.svg';
import classes from './Carousel.module.scss';

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  oneItemPerPage?: boolean;
}

type ArrowsEnabledType = {
  next: boolean;
  prev: boolean;
};

const Carousel = ({ oneItemPerPage = false, className, children, ...divProps }: Props) => {
  const { isMobile } = useActiveBreakpoint();
  const innerRef = useRef<HTMLDivElement | null>(null);
  const [arrowsEnabled, setArrowsEnabled] = useState<ArrowsEnabledType>({
    next: false,
    prev: false,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);

  const getItemWidth = (): number => {
    const allItems = innerRef.current?.childNodes as NodeListOf<HTMLDivElement>;
    if (!allItems.length) return 0;

    return parseFloat(getComputedStyle(allItems.item(0)).width);
  };

  const getColumnGap = (): number => {
    if (innerRef.current) return parseFloat(getComputedStyle(innerRef.current).columnGap);

    return 0;
  };

  const getItemsPerPage = (): number => {
    if (!innerRef.current) return 0;

    const viewportWidth = innerRef.current.clientWidth ?? 0;

    return Math.round((viewportWidth + getColumnGap()) / (getItemWidth() + getColumnGap()));
  };

  const getPagesCount = (): number => {
    return Math.ceil(React.Children.count(children) / getItemsPerPage());
  };

  const updateArrowsEnabledState = () => {
    if (!innerRef.current) return;

    setArrowsEnabled({
      prev: innerRef.current.scrollLeft !== 0,
      next: Math.round(innerRef.current.scrollLeft) !== innerRef.current.scrollWidth - innerRef.current.clientWidth,
    });
  };

  const scrollToOffset = (value: number) => {
    innerRef.current?.scroll({
      behavior: 'smooth',
      left: value,
    });
  };

  const scrollToPage = (page: number) => {
    if (!innerRef.current) return;
    scrollToOffset((page - 1) * getItemsPerPage() * (getItemWidth() + getColumnGap()));
  };

  const onNextItem = (): void => {
    scrollToPage(Math.min(getPagesCount(), currentPage + 1));
  };

  const onPrevItem = (): void => {
    scrollToPage(Math.max(1, currentPage - 1));
  };

  useEffect(() => {
    const innerElement = innerRef.current;
    if (!innerElement) return;

    const handleScroll = () => {
      let newCurrentPage = Math.round(innerElement.scrollLeft / innerElement.clientWidth) + 1;

      if (innerElement.scrollWidth - innerElement.clientWidth - innerElement.scrollLeft <= 1) {
        newCurrentPage = getPagesCount();
      }

      setCurrentPage(newCurrentPage);
      updateArrowsEnabledState();
    };

    const handleScrollThrottled = throttle(() => {
      handleScroll();
    }, 200);

    innerElement.addEventListener('scroll', handleScrollThrottled);
    window.addEventListener('resize', handleScrollThrottled);

    updateArrowsEnabledState();

    // eslint-disable-next-line consistent-return
    return () => {
      innerElement.removeEventListener('scroll', handleScrollThrottled);
      window.removeEventListener('resize', handleScrollThrottled);
    };
  }, [innerRef.current, children]);

  return (
    <div {...divProps} className={className}>
      <div className={classes.Carousel}>
        {!isMobile && (
          <div
            className={classNames(
              classes.Carousel__arrowWrapper,
              !arrowsEnabled.prev && classes.Carousel__svgWrapperFaded,
            )}
            onClick={onPrevItem}
          >
            <ArrowSvg />
          </div>
        )}

        <div className={classes.Carousel__inner} ref={innerRef}>
          {React.Children.map(children, (child) => (
            <CarouselItem fullWidth={oneItemPerPage}>{child}</CarouselItem>
          ))}
        </div>

        {!isMobile && (
          <div
            className={classNames(
              classes.Carousel__arrowWrapper,
              !arrowsEnabled.next && classes.Carousel__svgWrapperFaded,
            )}
            onClick={onNextItem}
          >
            <ArrowSvg />
          </div>
        )}
      </div>
      {!isMobile && (
        <CarouselPagination scrollToPage={scrollToPage} currentPage={currentPage} pagesCount={getPagesCount()} />
      )}
    </div>
  );
};

export default Carousel;
